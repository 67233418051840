.container {
  background-color: #f4f4ff;
  color: #27242c;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1.5rem;
  .heading {
    color: #e75e40;
    font-weight: 600;
    text-align: center;
    margin-bottom: 1.5rem;
  }

  .step {
    width: 100%;
    align-items: center;
    display: grid;
    grid-template-columns: 72px auto;
    column-gap: 1rem;
  }

  .arrowContainer {
    padding: 0.25rem 0;
    width: 100%;
    .arrowWrap {
      width: 72px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    svg path {
      fill: #e75e40;
    }
  }
}

.container.rtl {
  direction: rtl;
}
