@import "@styles/breakpoints.module.scss";

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  .introList {
    display: flex;
    flex-direction: row;

    .intro {
      display: none;

      &.active {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
      }
      .introIcon {
        width: 200px;
        height: 200px;
      }

      .title {
        color: #fff;
        font-size: 1.25rem;
        font-weight: bold;
      }

      .introText {
        color: #fff;
        font-size: 1rem;
        line-height: 1.5;
        padding-top: 0.5rem;
      }
    }
  }

  .circles {
    display: flex;
    justify-content: space-between;
    width: 42px;
    padding-top: 2rem;
    align-items: center;

    span {
      background-color: #fff;
      box-shadow: 0 2px 4px 0 rgba(55, 57, 70, 0.2);
      cursor: pointer;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      opacity: 0.7;

      &.active {
        width: 10px;
        height: 10px;
        opacity: 1;
      }
    }
  }
}

.container.transparent {
  .intro {
    .title {
      color: #27242c;
      font-weight: 600;
    }

    .introText {
      color: #27242c;
    }
  }

  .circles {
    span {
      background-color: #2a2961;
      box-shadow: none;
      cursor: pointer;
      opacity: 1;

      &.active {
        background-color: #e75e40;
      }
    }
  }
}
