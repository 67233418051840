@import "@styles/breakpoints.module.scss";

.logo {
  position: absolute;
  top: 0.75rem;
  left: 0.75rem;
}

.container {
  background-color: #fff;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  width: 100%;

  position: relative;
  overflow: hidden;

  .signInOptionsContainer {
    padding: 1rem;
    margin-bottom: 1.5rem;
  }

  .titleContainer {
    margin-bottom: 1.5rem;
    position: relative;

    .navigation {
      background-color: #fff;
      height: 1.625rem;
      margin: 0 0 1rem;

      .header {
        width: 100%;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.625rem;
        text-align: center;
        color: #27242c;
      }
    }

    .title {
      color: #373946;
      font-size: 1rem;
      font-weight: bold;
      text-align: center;
      margin: 0;
    }

    .subtitle {
      display: flex;
      justify-content: center;
      flex-direction: row;
      padding: 0.625rem 0 0;
      font-size: 0.875rem;
      line-height: 1.375rem;
      text-align: center;
      gap: 0.25rem;

      .subtitlePrompt {
        font-weight: 400;
        color: #27242c;
      }

      .subtitleLink {
        font-weight: 600;
        color: #e75e40;
        cursor: pointer;
      }
    }
  }

  .note {
    color: #373946;
    font-size: 1rem;
    text-align: center;
    line-height: 1.5;
    padding-bottom: 2rem;
  }

  .dividerContainer {
    color: #e75e40;
    font-size: 0.75rem;
    font-weight: bold;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem 0;
    position: relative;

    .dividerText {
      position: absolute;
      background-color: #fff;
      padding: 0 1rem;
    }

    .divider {
      width: 100%;
      height: 1px;
      background-color: #dadbe0;
    }
  }

  .thirdPartyButton {
    button {
      margin-bottom: 1rem;
    }
  }

  .closeText {
    position: absolute;
    font-size: 0.75rem;
    color: #9b9ca2;
    z-index: 1;
    right: 0;
    top: 0rem;
    line-height: 1.625rem;
  }
}

.rtl.container {
  .logo {
    left: auto;
    right: 0.75rem;
  }

  .closeText {
    right: auto;
    left: 0;
  }
}

@media only screen and (min-width: $breakpoint-small - 1) {
  .container {
    border-radius: 0.5rem;
    width: calc(471px - 2rem - 2rem);
    height: auto;

    .signInOptionsContainer {
      padding: 2rem;
    }
  }
}
