@import "@styles/breakpoints.module.scss";

.modalOverride {
  height: 100%;
  border-radius: 0;

  &.fullScreen {
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
}
@media only screen and (min-width: $breakpoint-small) {
  .modalOverride {
    top: 6.5rem;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0.5rem;
    height: auto;
  }

  .closeIcon {
    transform: scale(1.25);
    position: absolute;
    top: -2.25rem;
    right: 0;
    fill: #ffffff;
    cursor: pointer;
  }
}
