.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2147483647; // highest value? https://www.digitalocean.com/community/tutorials/css-z-index
  margin: 0 !important;
}

.main {
  position: absolute;
  background: white;
  top: auto;
  left: auto;
  right: auto;
  height: auto;
  width: auto;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.08);
}
