@import "@styles/breakpoints.module.scss";

.container {
  display: flex;
  background-image: linear-gradient(129deg, #2a2961, #321985 80%, #321985 80%);
  border-radius: 0.5rem;
  width: 100%;
  min-height: 522px;
  &.transparent {
    background-image: none;
  }
}

.intro {
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 0 2rem;
  width: 335px;
}

@media only screen and (min-width: $breakpoint-medium) {
  .intro {
    display: flex;
  }
}

@media only screen and (max-width: $breakpoint-small - 1) {
  .container {
    border-radius: 0;
    height: 100%;
  }
  .container.step1 {
    min-height: 100%;
    height: auto;
  }
}

.container.rtl {
  direction: rtl;
  height: 621px;
  &.step1 {
    min-height: 100%;
    height: auto;
  }
}
