.container {
  display: block;

  .field {
    position: relative;
  }

  label {
    color: #27242c;
    font-size: 0.875rem;
    font-weight: bold;
    display: block;
    padding-top: 1rem;
    padding-bottom: 0.25rem;
  }

  input {
    background-color: #fff;
    border: 0;
    border-bottom: 1px solid #dadbe0;
    caret-color: #e75e40;
    color: #373946;
    font-size: 1rem;
    padding-bottom: 0.75rem;
    width: 100%;
    -webkit-appearance: none;
    border-radius: 0;
    &::placeholder {
      color: #9b9ca2;
    }
  }

  &.filled input {
    border-bottom: 1px solid #2a2961;
  }

  &.disabled {
    label,
    input {
      color: #9b9ca2;
    }
    input {
      border-bottom: 1px solid #dadbe0;
    }
  }

  &.focused {
    label {
      color: #e75e40;
    }
    input {
      border-bottom: 1px solid #e75e40;
    }
  }

  .iconContainer {
    cursor: pointer; // desktop
    padding-bottom: 0.3rem;
    position: absolute;
    right: 0;
    bottom: 1px;

    &.disabled .icon {
      fill: #dadbe0;
    }
  }

  .message {
    color: #9b9ca2;
    padding-top: 0.5rem;
    font-size: 0.75rem;

    &.error {
      color: #d82727;
    }
  }
}

.container.rtl {
  text-align: right;
  direction: rtl;

  .iconContainer {
    right: inherit;
    left: 0;
  }
}
