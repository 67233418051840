@import "@styles/breakpoints.module.scss";

.container {
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.15);
  display: block;
  width: calc(471px - 2rem - 2rem);
  padding: 1.5rem 2rem 2rem;
  position: relative;

  .backContainer {
    cursor: pointer;
    position: absolute;
    top: 0.5rem;
    left: 1rem;
    padding: 1rem;

    .back {
      fill: #e75e40;
    }
  }

  .title {
    color: #373946;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    padding-bottom: 1.5rem;
  }

  .note {
    color: #373946;
    font-size: 1rem;
    text-align: center;
    line-height: 1.5;
    padding-bottom: 2rem;
  }

  .passwordContainer {
    margin-top: 1.75rem;
  }

  .buttonContainer {
    padding-top: 1.75rem;
  }

  .forgotPassword {
    color: #9b9ca2;
    cursor: pointer;
    font-size: 0.875rem;
    padding-top: 1rem;
    text-align: center;

    span {
      color: #e75e40;
      font-size: 0.875rem;
      font-weight: bold;
    }
  }
}

@media only screen and (max-width: $breakpoint-small - 1) {
  .container {
    border-radius: 0;
    height: 100%;
    padding: 1rem;
    width: 100%;
    .backContainer {
      left: 0;
      top: 0;
    }
  }
}

.container.rtl {
  .backContainer {
    top: 0.5rem;
    right: 0;
    padding: 1rem;
    left: auto;

    svg {
      transform: rotate(180deg);
    }
  }
}
