.button {
  border: 1px solid #fff;
  border-radius: 0.25rem;
  cursor: pointer;
  font-weight: bold;
  font-size: 0.875rem;
  height: 2.25rem;
  display: flex;
  align-items: center;
  outline: none;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  .content {
    padding: 0 1rem;
    width: 100%;
  }

  &.large {
    height: 3rem;
  }

  &.fullWidth {
    width: 100%;
  }

  &.textAlignStart {
    text-align: start;
  }

  &.primary,
  &.primaryFolded {
    background-image: linear-gradient(135deg, #e75e40, #f18254 100%);
    color: #fff;
    border: none;

    &:hover {
      background-color: #e75e40;
      background-image: none;
    }

    &.disabled {
      background-color: #dadbe0;
      background-image: none;
      color: #9b9ca2;
      cursor: not-allowed;
    }
  }

  &.secondary {
    background-color: #e75e40;
    color: #fff;
    border: none;

    &:hover {
      background-color: #f46e51;
    }

    &.disabled {
      background-color: #dadbe0;
      color: #9b9ca2;
      cursor: not-allowed;
    }
  }

  &.roundedSecondary {
    background: #e75e40;
    border-radius: 2.25rem;
    border: none;
    color: #fff;

    &:hover {
      background-color: #f46e51;
    }

    &.disabled {
      background-color: #dadbe0;
      color: #9b9ca2;
      cursor: not-allowed;
    }
  }

  &.secondaryWhite {
    background-color: #fff;
    border: none;
    color: #e75e40;

    &:hover {
      background-color: #ffefec;
    }

    &.disabled {
      background-color: #dadbe0;
      color: #9b9ca2;
      cursor: not-allowed;
    }
  }

  &.outlinePrimary {
    background-color: #fff;
    border: 1px solid #e85d40;
    color: #e75e40;

    &:hover {
      background-color: #ffefec;
    }

    &.disabled {
      border: 1px solid #979797;
      color: #9b9ca2;
      cursor: not-allowed;
    }
  }

  &.outlineSecondary {
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;

    &:hover {
      background-color: rgba(255, 255, 255, 0.15);
    }

    &.disabled {
      background-color: rgba(255, 255, 255, 0.15);
      border: 1px solid #fff;
      cursor: not-allowed;
    }
  }

  &.roundedOutlineSecondary {
    border: 0.0625rem solid #e75e40;
    border-radius: 1.625rem;

    background: #ffffff;
    color: #e75e40;

    &:hover {
      background: #ffefec;
    }

    &.disabled {
      background-color: rgba(255, 255, 255, 0.15);
      border: 1px solid #fff;
      cursor: not-allowed;
    }
  }

  &.facebook,
  &.google,
  &.emailAuth {
    background-color: #ffffff;
    border: 0.0625rem solid #dadbe0;
    border-radius: 2.25rem;
    color: #27242c;
    height: 3rem;
    width: 100%;
    padding: 0.75rem;
  }

  &.primaryFolded {
    .dashedBorder {
      border-color: #f15d3c;
      border-radius: 0 0.25rem 0 0.25rem;
      border-style: dashed;
      border-width: 1px 1px 0 0;
      height: 12px;
      width: 12px;
      position: absolute;
      top: 0;
      right: 0;
      background: #fff;
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      z-index: 1;
      right: 0;
      border-width: 6px;
      border-style: solid;
      border-color: transparent transparent #f15d3c #f15d3c;
      border-radius: 0px 0px 0px 4px;
      box-shadow: 0px 1px 4px rgb(42 41 97 / 25%);
    }
  }

  &.large.primaryFolded {
    .dashedBorder {
      height: 16px;
      width: 16px;
    }

    &:before {
      border-width: 8px;
    }
  }

  .hidden {
    visibility: hidden;
  }
}

.button.rtl {
  .dashedBorder {
    transform: scaleX(-1);
    right: unset;
    left: 0;
  }

  &.primaryFolded {
    &:before {
      transform: scaleX(-1);
      right: unset;
      left: 0;
    }
  }
}
