$faded-white: rgba(255, 255, 255, 0.5);

form.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .referralTitleContainer {
    position: relative;
    width: 100%;
    text-align: center;
    cursor: pointer;

    .referralTitle {
      color: white;
      font-weight: bold;
    }

    .chevron {
      position: absolute;
      right: 0;
      fill: white;
      width: 24px;
      height: 24px;
    }
  }

  .referralInput {
    display: none;
    width: 100%;
  }

  .referralField {
    flex: 1;

    label {
      display: none;
    }

    input {
      border-color: $faded-white;
      background: transparent;
      color: white;
      caret-color: white;

      &::placeholder {
        color: $faded-white;
      }

      &:focus {
        border-color: white;
      }
    }

    .message {
      color: $faded-white;

      &.error {
        color: white;
      }
    }

    &.filled {
      input {
        border-color: white;
      }
    }
  }

  .referralButton {
    margin-left: 1rem;
    margin-bottom: 1.5rem;
  }

  &.opened {
    .referralTitleContainer {
      margin-bottom: 1rem;
      cursor: unset;

      .chevron {
        display: none;
      }
    }

    .referralInput {
      display: flex;
    }
  }
}

form.container.rtl {
  .referralButton {
    margin-left: inherit;
    margin-right: 1rem;
  }
}
