.container {
  display: block;

  .field {
    position: relative;
  }

  input {
    background-color: #fff;
    border: 0;
    border-bottom: 1px solid #dadbe0;
    caret-color: #e75e40;
    color: #2a2961;
    font-size: 1.25rem;
    font-weight: bold;
    letter-spacing: 16px;
    line-height: 1.6;
    padding-bottom: 0.75rem;
    text-align: center;
    width: 100%;
  }

  &.filled input {
    border-bottom: 1px solid #2a2961;
  }

  &.focused input {
    border-bottom: 1px solid #e75e40;
  }

  .message {
    color: #9b9ca2;
    padding-top: 0.5rem;
    font-size: 0.75rem;

    &.error {
      color: #d82727;
    }
  }
}
