.container {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  cursor: pointer;
  direction: ltr;

  &:hover button {
    background-color: #e75e40;
    background-image: none;
  }

  button {
    flex: 1;
    &:before {
      content: " ";
      height: 6.5rem;
      transition: transform 0.2s ease-in-out;
      background: #fff;
      right: -40px;
      position: absolute;
      width: 4.125rem;
      transform: rotate(-30deg);
      z-index: 2;
    }
  }

  .preview {
    color: #f15d3c;
    border: 1px dashed #f15d3c;
    border-left-style: none;
    border-radius: 4px;
    height: 2.25rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    padding: 0 0.5rem;
    width: 3.35rem;
    z-index: 3;
    user-select: none;
    overflow: hidden;
  }

  .foldIcon {
    transition: transform 0.2s ease-in-out;
    position: absolute;
    right: 20px;
    top: 0px;
    height: 2.25rem;
    z-index: 1;

    svg {
      height: 2.25rem;
    }
  }

  &:hover {
    button {
      &:before {
        transform: rotate(-30deg) translateX(-8px);
      }
    }
    .foldIcon {
      transform: translateX(-8px);
    }
  }
}

.container.large {
  .preview {
    width: 3.75rem;
    height: 3rem;
    right: 0;
  }

  .foldIcon {
    top: 0px;
    height: 3rem;
    z-index: 1;

    svg {
      height: 3rem;
    }
  }
}
