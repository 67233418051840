.loadingIconContainer {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;

  .loadingIcon {
    animation: rotation 2s infinite linear;
    height: 24px;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
